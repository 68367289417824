import React from 'react';

export const Intro = ({ day }) => (
  <>
    <p>
      This is a part of my ongoing challenge of solving every Advent of Code
      puzzle in a different language.
    </p>
    <p>
      The challenge we're tackling today is available{' '}
      <a href={`https://adventofcode.com/2019/day/${day}`}>here</a>.
    </p>
  </>
);
